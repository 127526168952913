var lazyloadImages = null;
var lazyloadThrottleTimeout;

jQ(function () {
    lazyloadImages = document.querySelectorAll(".lazy");

    lazyload();

    document.addEventListener("scroll", lazyload);
    window.addEventListener("resize", lazyload);
    window.addEventListener("orientationChange", lazyload);
});

function lazyload () {
    if(lazyloadThrottleTimeout) {
        clearTimeout(lazyloadThrottleTimeout);
    }

    lazyloadThrottleTimeout = setTimeout(function() {
        var scrollTop = window.pageYOffset;
        lazyloadImages.forEach(function(img) {
            if(img.offsetTop < (window.innerHeight + scrollTop)) {
                if(img.tagName == 'IMG'){
                    img.src = img.dataset.src;
                } else {
                    img.style.backgroundImage = "url('" + img.dataset.src + "')";
                }
                img.classList.remove('lazy');
            }
        });
        if(lazyloadImages.length == 0) {
            document.removeEventListener("scroll", lazyload);
            window.removeEventListener("resize", lazyload);
            window.removeEventListener("orientationChange", lazyload);
        }
    }, 20);
}
